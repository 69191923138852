.employeeDashboardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 100px;
}

.employeeDashboardCard {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 234px;
  height: 165px;
  margin-right: 20px;
  background-color: #ff7201;
  color: white;
  border-radius: 20px;
  border: 3px solid #f78d2e;
}

@media (max-width: 768px) {
  .employeeDashboardContainer {
    flex-direction: column;
    align-items: center;
  }
  .employeeDashboardCard {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.cardInfoDashboard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cardNum {
  font-size: 40px;
  margin: 0;
}

.cardDes {
  margin: 0;
}

.icon {
  font-size: 45px;
  padding-right: 10px;
}

.icon2 {
  width: 100px;
}

.modalBtn {
  background-color: transparent;
  color: white;
  border: none;
  position: absolute;
  top: 0;
  right: 5px;
  cursor: pointer;
}

.employeeCardsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  margin-top: 30px;
}

.employeesCard {
  width: 470px;
  border-radius: 10px;
  border: 2px solid #e6e6e6;
  margin: 0px 10px 10px 0px;
}

.infoBtn {
  font-size: 12px;
  width: 90px;
  height: 30px;
  border-radius: 30px;
  color: white;
  background-color: #000000;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0px 10px 10px 0px;
}

.infoBtn:hover {
  color: #ff7201 !important;
}

.cards {
  display: flex;
  flex-direction: row;
  padding: 5px 0px 5px 0px;
}

.cardsInfo {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.cardName {
  font-size: 23px;
  margin: 0;
}

.cardPosition {
  margin: 0;
}

.ant-card .ant-card-body {
  padding: 10px;
}

.dropdownBtn {
  background-color: transparent;
  border: none;
  box-shadow: none;
  position: absolute;
  top: 0;
  right: 0;
}

.dropdownIcon {
  font-size: 30px;
}

.dropdownBtn:hover {
  border: none;
  color: black !important;
  box-shadow: none;
  outline: none;
}

.updateModal {
  height: 300px;
  overflow: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.updateStatusBtn {
  border-radius: 30px;
  width: 25px;
  height: 25px;
  border: none;
  box-shadow: none;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 100px;
  margin: 0px 10px 13px 0px;
}

.updateBtn {
  width: 135px;
  height: 135px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  color: white;
}

.employeeDetailsContainer {
  display: flex;
  flex-direction: row;
}

.detailsContainer2 {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
}

.filesContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
}

.personalInfo {
  background-color: #f5f5f5;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  height: 290px;
  width: 575px;
  display: flex;
  flex-direction: column;
}

.employmentInfo {
  margin-top: 30px;
  background-color: #f5f5f5;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  height: 340px;
  width: 575px;
}

.detailHeader {
  margin: 10px 0px 0px 0px;
  padding-top: 5px;
  text-align: center;
}

.avatar {
  width: 245px;
  height: 245px;
  line-height: 200px;
  border-radius: 0px;
  background: "#1890ff";
  color: "#fff";
}

.infoContainer {
  margin-top: 10px;
}

.info {
  display: flex;
  flex-direction: row;
  height: 30px;
  align-items: center;
  margin: 0px 10px 0px 10px;
}

.employeeDetailsBtn {
  width: 202px;
  height: 35px;
  border-radius: 10px;
  margin-right: 10px;
  background-color: green;
  border: none;
  color: white;
}

.infoBtnContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.infoName {
  width: 350px;
}

.infoNum {
  width: 250px;
}

.infoNum1 {
  width: 200px;
}/*# sourceMappingURL=employeeDashboard.css.map */