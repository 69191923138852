.employees-container {
  display: flex;
  height: 80vh;
  width: 80%;
  margin: 0px auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.employee-modal-title{
  font-weight: 600;
  font-size: 24;
  text-align: center;
}

.core-btn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0000FF;
  width: 200px;
  height: 200px;
  border-radius: 47px;
  cursor: pointer;
  border-color: transparent;
}

.core-btn:hover{
  background-color: #0000FF;
}

.update-details-btn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F5F5F5;
  width: 500px;
  height: 50px;
  border-radius: 13px;
  margin: 10;
}

.manpower-btn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color:#FF7201;
  width: 200px;
  height: 200px;
  border-radius: 47px;
  cursor: pointer;
  border-color: transparent;
}

.manpower-btn:hover{
  background-color:#FF7201;
}

.update-core-btn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0000FF;
  width: 200px;
  height: 200px;
  border-radius: 5px;
  cursor: pointer;
  border-color: transparent;
}
.update-core-btn:hover{
  background-color: #0000FF;
}

.update-manPower-btn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FF7201;
  width: 200px;
  height: 200px;
  border-radius: 5px;
  cursor: pointer;
  border-color: transparent;
}

.update-manPower-btn:hover{
  background-color: #FF7201;
}

.update-badRecord-btn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FF0000;
  width: 200px;
  height: 200px;
  border-radius: 5px;
  cursor: pointer;
  border-color: transparent;
}

.update-suspend-btn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #6D6D6D;
  width: 200px;
  height: 200px;
  border-radius: 5px;
  cursor: pointer;
  border-color: transparent;
}

.update-badRecord-btn:hover{
  background-color: #FF0000;
}

.core-form-title{
  font-weight: 600;
  font-size: 24;
}

.upload-img-span{
  font-weight: 700;
  font-size: 13px;
  color: #000000;
}

.upload-img-txt{
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
.department-dropdown{
  .ant-select-selector {
    background-color: #2C2C2C !important;

    .ant-select-selection-placeholder,.ant-select-selection-item{
      color: #ffffff;
    }
  }
}