.navigationContainer {
  background-color: #2C2C2C;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
}

.total-attendance-report-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 798px;
  height: 333px;
  border: 3px solid #f78d2e;
  border-radius: 19px;
  margin: 0 25px 25px 0;
  background-color: #f78d2e;
  color: white;
}

.weekly-attendance-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: "100%";
  height: 300.89px;
  border: 3px solid #f78d2e;
  border-radius: 19px;
  margin: 0 15px 25px 0;
  background-color: #f78d2e;
}

.daily-attendance-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: "100%";
  height: 298px;
  border-radius: 19px;
  border: 3px;
  border: 3px solid #f78d2e;
  margin: 0 15px 25px 0;
  background-color: #f78d2e;
}

.latest-news-container {
  width: 432px;
  height: 1090px;
  top: 761px;
  background: #efeeee;
  border: 3px solid #e1e1e1;
  border-radius: 19px;
  margin: 0px 20px 20px 0;
  padding: 30px;
}
.latest-news-container .ant-card {
  height: 270px;
  margin-top: 5%;
}
.latest-news-container .ant-card .ant-card-body {
  background-color: rgba(44, 44, 44, 0.68);
  height: 50px;
}
.latest-news-container .ant-card .ant-card-meta-title {
  color: #fff;
  text-transform: capitalize;
}
.latest-news-container .ant-card img {
  height: 220px;
}

.right-side-card-container {
  width: 432px;
  background: #efeeee;
  border: 3px solid #e1e1e1;
  border-radius: 19px;
  margin: 0px 20px 25px 0;
}

.dashboard-container {
  display: flex;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
}

.dashboard-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.summary-count-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 50px;
}

.summary-count-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 234px;
  height: 165px;
  margin-right: 20px;
  background-color: #ff7201;
  color: white;
  border-radius: 20px;
  text-align: center;
}

.dropdown-btn {
  background-color: transparent;
  border: none;
  box-shadow: none;
  position: absolute;
  top: 0;
  right: 0;
}

.modal-btn {
  background-color: transparent;
  color: white;
  border: none;
  position: absolute;
  top: 0;
  right: 5px;
  cursor: pointer;
}

.summary-modal-title {
  font-weight: 600;
  font-size: 24;
  text-align: center;
}

.icon-lonely {
  width: 70px;
  margin-right: 20px;
}

.attendance-gallery-container {
  width: 796px;
  background: #efeeee;
  border: 3px solid #e1e1e1;
  border-radius: 19px;
  margin: 0 20px 0 0;
  padding: 0 20px 0 20px;
}

.attendance-gallery-cards {
  display: flex;
  justify-content: space-between;
  margin: 0 20px 0 2px;
  text-align: center;
}

.attendance-gallery-cards p {
  font-size: 12px;
  margin: 2px;
}

.attendance-gallery-cards h3 {
  font-size: 16px;
  margin: 4px 2px 2px 0;
}

.view-request-btn {
  width: 90px;
  height: 30px;
  border-radius: 30px;
  color: white;
  background: #364042;
  cursor: pointer;
  position: absolute;
  justify-items: center;
  bottom: 0;
  right: 0;
  margin: 0px 40px 35px 0px;
}

.icon-doc {
  width: 20px;
  height: 20px;
}

.view-request-modal-container p {
  background-color: #e1e1e1;
  padding: 20px;
}

.notify-button {
  width: 95px;
  height: 30px;
  border-radius: 10px;
  color: white;
  background: #ff9d4d;
  cursor: pointer;
  position: absolute;
  justify-items: center;
  bottom: 0;
  right: 0;
  margin: 0px 20px 10px 0px;
}

.view-all-btn {
  color: #ff9d4d;
  text-decoration: underline;
  cursor: pointer;
}

.view-all-modal-container {
  padding: 30px;
  width: 568px;
}

.modal-photo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-person-photo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  margin: 40px 20px 20px 20px;
}

.modal-view-header {
  text-align: center;
  border-bottom: 2px solid #364042;
}/*# sourceMappingURL=dashboard.css.map */