.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2c2c2c;
  margin: 0;
  padding: 0;
  height: 931px;
  overflow: auto;
}

.logo {
  width: 500px;
  
  margin-bottom: 100px;
}

.input {
  border-radius: 25px;
  width: 442px;
  height: 74px;
  border: 1px solid #f47f20;
  background-color: white;
}

.login-form-button {
  width: 442px;
  height: 54px;
  background-color: #f47f20;
  border-radius: 50px;
}

.login-form-button:hover {
  width: 442px;
  height: 54px;
  background-color: #f47f20;
  border-radius: 50px;
}

.btnText {
  color: white;
  font-weight: 500;
}

.text {
  color: #f47f20;
  text-align: center;
}

.text-remember {
  margin: 0px 185px 0px 20px;
  color: #f47f20;
}

.input:hover,
.input:focus {
  background-color: white;
  
}

a {
  color: #f47f20;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #f47f20;
}
